const analytics = () => {
    $('a[data-analytics-event]:not(.js-analytics-done)').each(function() {
        var link = $(this);
        var e = link.attr('data-analytics-event').split('|');

        // click
        link.on('click', function() {
            track(e[0], e[1], e[2])
        });

        // done
        link.addClass('js-analytics-done');
    });
}

const page = (url, title) => {
    dataLayer.push({
        'virtualPageUrl': url,
        'virtualPageTitle': title,
        'event': 'VirtualPageView'
    });
}

const track = (category, action, label) => {
    dataLayer.push({
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label,
        'event': 'EventTracking'
    });
}

export default analytics;
export { page };
